<template>
  <v-col
    class="flex-grow-1"
  >
    <o-loader
      v-if="loading"
    />
    <o-form
      v-else
      @submit="onSubmit"
    >
      <v-row>
        <v-col>
          <o-text-field
            v-model="form.name"
            :rules="validation.name"
            label="labels.category.name"
            prepend-icon="mdi-rename-box"
          />
          <o-icon-field
            v-model="form.icon"
            :rules="validation.icon"
            :color="form.color"
            label="labels.category.icon"
          />
          <o-color-field
            v-model="form.color"
            :rules="validation.color"
            label="labels.category.color"
          />
          <o-submit />
        </v-col>
      </v-row>
    </o-form>
  </v-col>
</template>

<script>
  import { hydrate } from '@utils/form';
  import { clone } from 'lodash';

  export default {
    props: {
      id: {
        type: Number,
        default: null,
      },
    },

    data: function () {
      return {
        mode: 'create',
        loading: false,
        form: {
          name: '',
          color: '0000FF',
          icon: 'map-marker',
        },
        validation: require('@/validation/entities/category.json'),
      };
    },

    created () {
      if (this.id) {
        this.loading = true;
        this.mode = 'update';
        this.$apollo.query({
          query: require('@gql/views/admin/components/information/window/categoryForm/getCategory.gql'),
          client: 'information',
          fetchPolicy: 'no-cache',
          variables: {
            id: parseInt(this.id),
          },
        })
          .then((result) => {
            hydrate(this.form, result.data.getCategory);
            this.loading = false;
          }).catch(() => {
            this.$flash(null, 'error');
          })
        ;
      }
    },

    methods: {
      onSubmit () {
        this.loading = true;

        const variables = {
          input: clone(this.form),
        };

        let mutation;
        switch (this.mode) {
          case 'create':
            mutation = require('@gql/mutations/category/createCategory.gql');
            break;
          case 'update':
            mutation = require('@gql/mutations/category/updateCategory.gql');
            variables.input.id = this.id;
            break;
        }

        this.$apollo.mutate({
          mutation: mutation,
          client: 'information',
          variables: variables,
        }).then((result) => {
          this.$emit('submit', result[this.mode + 'Category']);
          this.$flash('messages.views.admin.components.information.window.categoryForm.' + this.mode + '.success', 'success');
        }).catch((e) => {
          this.$flash('messages.views.admin.components.information.window.categoryForm.' + this.mode + '.error', 'error');
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
